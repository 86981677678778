const modeEnv = process.env.NODE_ENV || 'development';
let port = ''
let urlGames = ''
let urlGamesBack = ''
let urlChat = ''

// check if mode is in developement or production (Build)
if (modeEnv === 'development') port = ":8000" // :8000
if (modeEnv === 'production') port = '' // para demoeventos se debe colocar el mismo puerto del BACK ':3018'


if (modeEnv === 'development') urlGames = window.location.protocol + '//' + window.location.hostname + ":5000" // :5000
if (modeEnv === 'production') urlGames = `http://demoeventos.digital:3010`

if (modeEnv === 'development') urlGamesBack = window.location.protocol + '//' + window.location.hostname + ":9000" // :5000
if (modeEnv === 'production') urlGamesBack = `http://demoeventos.digital:3010`

if (modeEnv === 'development') urlChat = window.location.protocol + '//' + window.location.hostname + ":3005" // :5000
if (modeEnv === 'production') urlChat = `https://prueba.cxdigitalroche.com`

export const APIGAMES = urlGames
export const APIGAMESBACK = urlGamesBack
export const CHATURL = urlChat
export const API = window.location.protocol + '//' + window.location.hostname + port
export const ws = window.location.protocol + "//" + window.location.hostname + port
export const uri = process.env.REACT_APP_API_URL
export const adminURL = process.env.REACT_APP_ADMIN_URL
